import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootCreativeIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-creative-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9992 0.109375H29.9992V0.109926H0.000267457V30.1094H0V60.1095H30V30.11H30.0003V30.1094H59.9992V0.109375Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9992 0.109375V-0.890625H28.9992V0.109375H29.9992ZM59.9992 0.109375H60.9992V-0.890625H59.9992V0.109375ZM29.9992 0.109926V1.10993H30.9992V0.109926H29.9992ZM0.000267457 0.109926V-0.890074H-0.999733V0.109926H0.000267457ZM0.000267457 30.1094V31.1094H1.00027V30.1094H0.000267457ZM0 30.1094V29.1094H-1V30.1094H0ZM0 60.1095H-1V61.1095H0V60.1095ZM30 60.1095V61.1095H31V60.1095H30ZM30 30.11V29.11H29V30.11H30ZM30.0003 30.11V31.11H31.0003V30.11H30.0003ZM30.0003 30.1094V29.1094H29.0003V30.1094H30.0003ZM59.9992 30.1094V31.1094H60.9992V30.1094H59.9992ZM29.9992 1.10938H59.9992V-0.890625H29.9992V1.10938ZM30.9992 0.109926V0.109375H28.9992V0.109926H30.9992ZM29.9992 -0.890074H0.000267457V1.10993H29.9992V-0.890074ZM-0.999733 0.109926V30.1094H1.00027V0.109926H-0.999733ZM0.000267457 29.1094H0V31.1094H0.000267457V29.1094ZM-1 30.1094V60.1095H1V30.1094H-1ZM0 61.1095H30V59.1095H0V61.1095ZM31 60.1095V30.11H29V60.1095H31ZM30 31.11H30.0003V29.11H30V31.11ZM31.0003 30.11V30.1094H29.0003V30.11H31.0003ZM59.9992 29.1094H30.0003V31.1094H59.9992V29.1094ZM58.9992 0.109375V30.1094H60.9992V0.109375H58.9992Z'}
        fill={strokeColor}
        mask={'url(#root-creative-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'6.4502'}
        x2={'6.45019'}
        y1={'1.12244'}
        y2={'60.1099'}
      />
      <line
        stroke={strokeColor}
        x1={'46.9564'}
        x2={'46.9564'}
        y1={'1.12244'}
        y2={'30.1098'}
      />
      <line
        stroke={strokeColor}
        x1={'5.9502'}
        x2={'30.0009'}
        y1={'17.204'}
        y2={'17.204'}
      />
      <line
        stroke={strokeColor}
        x1={'0.507263'}
        x2={'29.2415'}
        y1={'49.6099'}
        y2={'49.6099'}
      />
      <line
        stroke={strokeColor}
        x1={'29.2343'}
        x2={'29.2343'}
        y1={'1.12244'}
        y2={'60.1099'}
      />
      <rect
        fill={strokeColor}
        height={'24.1089'}
        width={'17.5338'}
        x={'29.1758'}
        y={'4.98083'}
      />
      <rect
        fill={strokeColor}
        height={'9.49368'}
        width={'5.94937'}
        x={'0.507263'}
        y={'49.603'}
      />
    </svg>
  );
}

RootCreativeIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

