import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H4Styles, LinkStyles, P1Styles } from '@root/gatsby-contentful-core/src/components/text-styles';
export default function JobDescription({ description }) {
  return (
    <section css={styles.section}>
      <div
        css={styles.textContent}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
}

JobDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContent: {
    width: 'min(100%, 800px)',
    margin: '60px 20px 30px',
    // Styling below accounts for all available cases that would come from Lever's rich-text editor/API.
    '*': {
      color: Colors.nearBlack(),
      margin: 0,
    },
    'p, span, div': {
      margin: '20px 0px',
      ...P1Styles,
    },
    a: { ...LinkStyles },
    'h3, h4': {
      ...H4Styles,
      margin: '1.2em 0 0.3em',
    },
    ul: {
      paddingLeft: 20,
      textAlign: 'left',
      '* + *': { marginTop: '0.7em' },
    },
    li: {
      margin: '10px 0px 10px',
      ...P1Styles,
    },
    // In Lever's rich-text editor, when you add a "headline 3" it applies this 18px inline styling.
    // None of their other headlines modify the markup or add any styling.
    '*[style*="font-size: 18px"]': {
      lineHeight: '2em',
      fontSize: '22px !important',
      ...Responsive.md({ fontSize: '25px !important' }),
    },
  },
});
