import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootPeopleIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size + 1}
      viewBox={`0 0 ${size} ${size + 1}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-people-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9991 0.813477H29.9991V0.813846H0V30.8134V30.8138V60.8134H30V30.8138V30.8135H59.9991V0.813477Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9991 0.813477V-0.186523H28.9991V0.813477H29.9991ZM59.9991 0.813477H60.9991V-0.186523H59.9991V0.813477ZM29.9991 0.813846V1.81385H30.9991V0.813846H29.9991ZM0 0.813846V-0.186154H-1V0.813846H0ZM0 60.8134H-1V61.8134H0V60.8134ZM30 60.8134V61.8134H31V60.8134H30ZM30 30.8135V29.8135H29V30.8135H30ZM59.9991 30.8135V31.8135H60.9991V30.8135H59.9991ZM29.9991 1.81348H59.9991V-0.186523H29.9991V1.81348ZM30.9991 0.813846V0.813477H28.9991V0.813846H30.9991ZM29.9991 -0.186154H0V1.81385H29.9991V-0.186154ZM-1 0.813846V30.8134H1V0.813846H-1ZM-1 30.8134V30.8138H1V30.8134H-1ZM-1 30.8138V60.8134H1V30.8138H-1ZM0 61.8134H30V59.8134H0V61.8134ZM31 60.8134V30.8138H29V60.8134H31ZM31 30.8138V30.8135H29V30.8138H31ZM59.9991 29.8135H30V31.8135H59.9991V29.8135ZM58.9991 0.813477V30.8135H60.9991V0.813477H58.9991Z'}
        fill={strokeColor}
        mask={'url(#root-people-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'14.304'}
        x2={'60.0001'}
        y1={'16.0095'}
        y2={'16.0095'}
      />
      <line
        stroke={strokeColor}
        x1={'15.0703'}
        x2={'15.0703'}
        y1={'60.8134'}
        y2={'15.4969'}
      />
    </svg>
  );
}

RootPeopleIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
