import GreenhouseOpenPositionsSection from '@root/careers/src/components/greenhouse-open-positions-section';
import JobContent from '@root/careers/src/components/job-content';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { getGreenhouseJobProvider } from '@root/careers/src/contexts/greenhouse-job-context';

const JobContentSection = ({
  jobListingContentfulData, jobId, data, trackClick,
}) => {
  const JobProvider = getGreenhouseJobProvider();

  return (
    <JobProvider
      data={data}
    >
      <JobContent
        jobId={jobId}
        jobListingContentfulData={jobListingContentfulData}
      />
      <GreenhouseOpenPositionsSection trackClick={trackClick} />
    </JobProvider>
  );
};

JobContentSection.propTypes = {
  data: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  jobListingContentfulData: PropTypes.object.isRequired,
  trackClick: PropTypes.func.isRequired,
};

export default JobContentSection;
