import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootMarketingIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-marketing-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M0.000265407 0H30H30.0003H60V30H30.0003H30H0.000265407V0ZM0 30H30V60H0V30Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M0.000265407 0V-1H-0.999735V0H0.000265407ZM60 0H61V-1H60V0ZM60 30V31H61V30H60ZM0.000265407 30H-0.999735V31H0.000265407V30ZM30 30H31V29H30V30ZM0 30V29H-1V30H0ZM30 60V61H31V60H30ZM0 60H-1V61H0V60ZM30 -1H0.000265407V1H30V-1ZM30 1H30.0003V-1H30V1ZM30.0003 1H60V-1H30.0003V1ZM59 0V30H61V0H59ZM60 29H30.0003V31H60V29ZM30.0003 29H30V31H30.0003V29ZM0.000265407 31H30V29H0.000265407V31ZM-0.999735 0V30H1.00027V0H-0.999735ZM30 29H0V31H30V29ZM31 60V30H29V60H31ZM0 61H30V59H0V61ZM-1 30V60H1V30H-1Z'}
        fill={strokeColor}
        mask={'url(#root-marketing-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'0.609007'}
        x2={'29.3432'}
        y1={'0.661062'}
        y2={'29.6484'}
      />
      <line
        stroke={strokeColor}
        x1={'29.1055'}
        x2={'29.1055'}
        y1={'0.506287'}
        y2={'29.367'}
      />
      <line
        stroke={strokeColor}
        x1={'0.253906'}
        x2={'29.1147'}
        y1={'29.5'}
        y2={'29.5'}
      />
      <line
        stroke={strokeColor}
        x1={'0.253906'}
        x2={'29.1147'}
        y1={'39.6262'}
        y2={'39.6262'}
      />
      <line
        stroke={strokeColor}
        x1={'0.253906'}
        x2={'29.1147'}
        y1={'49.3733'}
        y2={'49.3733'}
      />
    </svg>
  );
}

RootMarketingIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
