import HubspotFormsContext from '@root/joinroot.com/src/contexts/hubspot-forms/hubspot-forms-context';
import PropTypes from '@root/vendor/prop-types';
import { useState } from '@root/vendor/react';

export default function HubspotFormsProvider({ children, forms }) {
  const [data] = useState({ forms });

  return (
    <HubspotFormsContext.Provider value={data}>
      {children}
    </HubspotFormsContext.Provider>
  );
}

HubspotFormsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
};
