import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootCustomerServiceIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size + 1}
      viewBox={`0 0 ${size} ${size + 1}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-customer-service-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9992 0.405273H29.9992V0.405406H0V30.3194V30.4054V60.3194H30V30.4054V30.4053H59.9992V0.405273Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9992 0.405273V-0.594727H28.9992V0.405273H29.9992ZM59.9992 0.405273H60.9992V-0.594727H59.9992V0.405273ZM29.9992 0.405406V1.40541H30.9992V0.405406H29.9992ZM0 0.405406V-0.594594H-1V0.405406H0ZM0 60.3194H-1V61.3194H0V60.3194ZM30 60.3194V61.3194H31V60.3194H30ZM30 30.4053V29.4053H29V30.4053H30ZM59.9992 30.4053V31.4053H60.9992V30.4053H59.9992ZM29.9992 1.40527H59.9992V-0.594727H29.9992V1.40527ZM30.9992 0.405406V0.405273H28.9992V0.405406H30.9992ZM29.9992 -0.594594H0V1.40541H29.9992V-0.594594ZM-1 0.405406V30.3194H1V0.405406H-1ZM-1 30.3194V30.4054H1V30.3194H-1ZM-1 30.4054V60.3194H1V30.4054H-1ZM0 61.3194H30V59.3194H0V61.3194ZM31 60.3194V30.4054H29V60.3194H31ZM31 30.4054V30.4053H29V30.4054H31ZM59.9992 29.4053H30V31.4053H59.9992V29.4053ZM58.9992 0.405273V30.4053H60.9992V0.405273H58.9992Z'}
        fill={strokeColor}
        mask={'url(#root-customer-service-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'44.6875'}
        x2={'44.6875'}
        y1={'29.8995'}
        y2={'15.0894'}
      />
      <line
        stroke={strokeColor}
        x1={'59.5005'}
        x2={'59.5005'}
        y1={'29.8996'}
        y2={'15.0895'}
      />
      <line
        stroke={strokeColor}
        x1={'13.9302'}
        x2={'13.9302'}
        y1={'42.0513'}
        y2={'15.0893'}
      />
      <line
        stroke={strokeColor}
        x1={'13.1641'}
        x2={'45.1894'}
        y1={'14.5894'}
        y2={'14.5894'}
      />
      <line
        stroke={strokeColor}
        x1={'13.1641'}
        x2={'20.2527'}
        y1={'42.057'}
        y2={'42.057'}
      />
      <circle
        cx={'20.5058'}
        cy={'41.2917'}
        fill={strokeColor}
        r={'2.02532'}
      />
      <rect
        fill={strokeColor}
        height={'8.98734'}
        width={'4.55696'}
        x={'9.875'}
        y={'23.1901'}
      />
      <rect
        fill={strokeColor}
        height={'7.21519'}
        width={'4.55696'}
        x={'43.9258'}
        y={'23.1901'}
      />
    </svg>
  );
}

RootCustomerServiceIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

