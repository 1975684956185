import ArtDirectedGatsbyImage from '@root/gatsby-contentful-core/src/components/art-directed-gatsby-image';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { getImage } from 'gatsby-plugin-image';
export default function SharedJobListingInfo({
  applyButtonCopy,
  applyButtonDescription,
  applyUrl,
  sharedJobListingInfoPhoto,
  mobileSharedJobListingPhoto,
  sharedJobListingText,
}) {
  return (
    <>
      <section css={styles.getInTouchSection}>
        <div css={styles.getInTouchWrap}>
          {renderRichText(applyButtonDescription)}

          <LinkButton
            buttonStyles={styles.button}
            to={applyUrl}
            type={LinkButtonModel.types.PRIMARY}
          >
            {applyButtonCopy}
          </LinkButton>
        </div>
      </section>

      <section css={styles.aboutRootSection}>
        {renderRichText(sharedJobListingText)}
      </section>
      <section css={styles.imageSection}>
        <ArtDirectedGatsbyImage
          alt={'The people of Root'}
          desktopImg={getImage(sharedJobListingInfoPhoto.gatsbyImageData)}
          mobileImg={getImage(mobileSharedJobListingPhoto?.gatsbyImageData)}
          style={imageWrapperStyles}
        />
      </section>
    </>
  );
}

SharedJobListingInfo.propTypes = {
  applyButtonCopy: PropTypes.string,
  applyButtonDescription: PropTypes.object,
  applyUrl: PropTypes.string,
  mobileSharedJobListingPhoto: PropTypes.object,
  sharedJobListingInfoPhoto: PropTypes.object,
  sharedJobListingText: PropTypes.object,
};

const styles = StyleSheet.create({
  getInTouchSection: {
    display: 'flex',
    justifyContent: 'center',
    padding: '100px 20px',
  },
  getInTouchWrap: {
    width: 'min(100%, 690px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    p: { marginTop: 12 },
  },
  button: { marginTop: 36 },
  aboutRootSection: {
    display: 'flex',
    justifyContent: 'center',
    padding: '100px 20px',
    backgroundColor: Colors.nearWhite(),
    '& > div': { width: 'min(100%, 800px)' },
    '& div h4 ~ h4': { marginTop: '1.2em' },
  },
  imageSection: {
    padding: '60px 20px',
    ...Responsive.md({
      padding: '100px 20px',
    }),
  },
});

const imageWrapperStyles = {
  width: 'min(100%, 926px)',
  margin: '0 auto',
};
