import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootDataScienceIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-data-science-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9992 0.405273H29.9992V0.405406H0.00011634V29.7715H0V59.7715H30V30.4054H30.0001V30.4053H59.9992V0.405273Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9992 0.405273V-0.594727H28.9992V0.405273H29.9992ZM59.9992 0.405273H60.9992V-0.594727H59.9992V0.405273ZM29.9992 0.405406V1.40541H30.9992V0.405406H29.9992ZM0.00011634 0.405406V-0.594594H-0.999884V0.405406H0.00011634ZM0.00011634 29.7715V30.7715H1.00012V29.7715H0.00011634ZM0 29.7715V28.7715H-1V29.7715H0ZM0 59.7715H-1V60.7715H0V59.7715ZM30 59.7715V60.7715H31V59.7715H30ZM30 30.4054V29.4054H29V30.4054H30ZM30.0001 30.4054V31.4054H31.0001V30.4054H30.0001ZM30.0001 30.4053V29.4053H29.0001V30.4053H30.0001ZM59.9992 30.4053V31.4053H60.9992V30.4053H59.9992ZM29.9992 1.40527H59.9992V-0.594727H29.9992V1.40527ZM30.9992 0.405406V0.405273H28.9992V0.405406H30.9992ZM29.9992 -0.594594H0.00011634V1.40541H29.9992V-0.594594ZM-0.999884 0.405406V29.7715H1.00012V0.405406H-0.999884ZM0.00011634 28.7715H0V30.7715H0.00011634V28.7715ZM-1 29.7715V59.7715H1V29.7715H-1ZM0 60.7715H30V58.7715H0V60.7715ZM31 59.7715V30.4054H29V59.7715H31ZM30 31.4054H30.0001V29.4054H30V31.4054ZM31.0001 30.4054V30.4053H29.0001V30.4054H31.0001ZM59.9992 29.4053H30.0001V31.4053H59.9992V29.4053ZM58.9992 0.405273V30.4053H60.9992V0.405273H58.9992Z'}
        fill={strokeColor}
        mask={'url(#root-data-science-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'1.11683'}
        x2={'29.5979'}
        y1={'1.57258'}
        y2={'30.3068'}
      />
      <line
        stroke={strokeColor}
        x1={'59.4593'}
        x2={'28.9529'}
        y1={'0.766236'}
        y2={'30.0068'}
      />
      <line
        stroke={strokeColor}
        x1={'29.3508'}
        x2={'0.616611'}
        y1={'29.3575'}
        y2={'59.6107'}
      />
      <circle
        cx={'13.5447'}
        cy={'13.4433'}
        fill={strokeColor}
        r={'2.02532'}
      />
      <circle
        cx={'46.33'}
        cy={'13.4433'}
        fill={strokeColor}
        r={'2.02532'}
      />
      <circle
        cx={'13.5447'}
        cy={'45.722'}
        fill={strokeColor}
        r={'2.02532'}
      />
    </svg>
  );
}

RootDataScienceIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

