import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootProductIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size + 1}
      viewBox={`0 0 ${size} ${size + 1}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-product-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9992 0.813477H29.9992V0.814396H0.00023584V30.8135H0V60.8136H30V30.8144H30.0003V30.8135H59.9992V0.813477Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9992 0.813477V-0.186523H28.9992V0.813477H29.9992ZM59.9992 0.813477H60.9992V-0.186523H59.9992V0.813477ZM29.9992 0.814396V1.8144H30.9992V0.814396H29.9992ZM0.00023584 0.814396V-0.185604H-0.999764V0.814396H0.00023584ZM0.00023584 30.8135V31.8135H1.00024V30.8135H0.00023584ZM0 30.8135V29.8135H-1V30.8135H0ZM0 60.8136H-1V61.8136H0V60.8136ZM30 60.8136V61.8136H31V60.8136H30ZM30 30.8144V29.8144H29V30.8144H30ZM30.0003 30.8144V31.8144H31.0003V30.8144H30.0003ZM30.0003 30.8135V29.8135H29.0003V30.8135H30.0003ZM59.9992 30.8135V31.8135H60.9992V30.8135H59.9992ZM29.9992 1.81348H59.9992V-0.186523H29.9992V1.81348ZM30.9992 0.814396V0.813477H28.9992V0.814396H30.9992ZM29.9992 -0.185604H0.00023584V1.8144H29.9992V-0.185604ZM-0.999764 0.814396V30.8135H1.00024V0.814396H-0.999764ZM0.00023584 29.8135H0V31.8135H0.00023584V29.8135ZM-1 30.8135V60.8136H1V30.8135H-1ZM0 61.8136H30V59.8136H0V61.8136ZM31 60.8136V30.8144H29V60.8136H31ZM30 31.8144H30.0003V29.8144H30V31.8144ZM31.0003 30.8144V30.8135H29.0003V30.8144H31.0003ZM59.9992 29.8135H30.0003V31.8135H59.9992V29.8135ZM58.9992 0.813477V30.8135H60.9992V0.813477H58.9992Z'}
        fill={strokeColor}
        mask={'url(#root-product-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'29.2343'}
        x2={'29.2343'}
        y1={'1.82654'}
        y2={'30.8139'}
      />
      <rect
        fill={strokeColor}
        height={'28.4924'}
        width={'30.1362'}
        x={'29.1758'}
        y={'1.46362'}
      />
    </svg>
  );
}

RootProductIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
