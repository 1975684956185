import CoreLink from '@root/gatsby-contentful-core/src/components/core-link';
import JobListTileHeader from '@root/careers/src/components/job-list-tile-header';
import JobPosting from '@root/careers/src/models/job-posting';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import environmentalizeUrl from '@root/gatsby-contentful-core/src/utils/environmentalize-url';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H5, P1 } from '@root/gatsby-contentful-core/src/components/text';

function JobListTile({
  trackClick,
  team,
  jobListingsForTeam,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={isOpen ? 'is-open' : ''}
      css={styles.jobListTileContainer}
    >
      <JobListTileHeader
        isOpen={isOpen}
        numberOfOpenPositions={jobListingsForTeam.length}
        team={team}
        toggleIsOpen={() => setIsOpen(!isOpen)}
      />
      <div css={styles.jobListingsContainer}>
        {jobListingsForTeam.sort((a, b) => a.jobTitle.localeCompare(b.jobTitle)).map((jobListing, index) => {
          const isLastItem = index === jobListingsForTeam.length - 1;
          const itemStyles = [styles.jobListingContainer];

          if (!isLastItem) {
            itemStyles.push(styles.jobListingContainerBorder);
          }

          return (
            <div
              css={itemStyles}
              key={jobListing.id}
            >
              <CoreLink
                css={styles.jobListingLink}
                environmentalizeUrlFnToUse={environmentalizeUrl}
                isTabbable={isOpen}
                onClick={() => trackClick(`JOB_POSTING_LINK_${jobListing.id}`)}
                to={`${environment.websiteBaseUrl}/careers/${jobListing.id}`}
              >
                <H5 css={styles.jobListingTitle}>{jobListing.jobTitle}</H5>
                <P1 css={styles.jobListingLocation}>{jobListing.location}</P1>
              </CoreLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}

JobListTile.propTypes = {
  jobListingsForTeam: PropTypes.arrayOf(PropTypes.instanceOf(JobPosting)).isRequired,
  team: PropTypes.string.isRequired,
  trackClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  jobListTileContainer: {
    borderBottom: `2px solid ${Colors.gray20()}`,
    maxWidth: 700,
    overflow: 'hidden',

    ...Responsive.md({
      margin: '0 auto',
    }),
  },

  jobListTileHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
  },

  jobListingLink: {
    textDecoration: 'none',
    padding: '20px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Colors.black(),
    transition: 'color 200ms cubic-bezier(0.37, 0, 0.28, 1)',
    cursor: 'pointer',

    ...Responsive.sm({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),

    '&:hover': {
      color: Colors.rootOrange(),
      textDecoration: 'none',
    },
  },

  jobListingTitle: {
    color: 'inherit',
  },

  jobListingLocation: {
    ...Responsive.sm({
      justifySelf: 'flex-end',
      marginLeft: 15,
      textAlign: 'right',
      whiteSpace: 'nowrap',
    }),
  },

  jobListingsContainer: {
    transition: 'all 300ms cubic-bezier(0,1,0,1)',
    maxHeight: 0,

    '.is-open &': {
      maxHeight: 2000,
      transition: 'all 350ms ease-in',
    },
  },

  jobListingContainerBorder: {
    borderBottom: '1px solid',
    borderBottomColor: Colors.gray20(),
  },
});

export default JobListTile;
