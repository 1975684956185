export default (
  <svg
    display={'block'}
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      clipRule={'evenodd'}
      d={'M12 0.0507812L17.1064 3.93614V1.00399H21.1915L21.1544 7.01622L24 9.18139V23.1277H22.4681V9.95404H1.53191V23.1277H0V9.18139L12 0.0507812ZM10.9787 13.0715H5.0635V23.1277H10.9787V13.0715ZM13.0213 13.0715H16.0851V16.0883H13.0213V13.0715ZM16.0851 17.094H13.0213V20.1108H16.0851V17.094ZM17.1064 13.0715H20.1702V16.0883H17.1064V13.0715ZM20.1702 17.094H17.1064V20.1108H20.1702V17.094Z'}
      fillRule={'evenodd'}
    />
  </svg>
);
