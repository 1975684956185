
import JobListTile from '@root/careers/src/components/job-list-tile';
import JobPosting from '@root/careers/src/models/job-posting';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H2 } from '@root/gatsby-contentful-core/src/components/text';
import { useGreenhouseJobContext } from '@root/careers/src/contexts/greenhouse-job-context';

const GreenhouseOpenPositionsSection = ({ trackClick }) => {
  const [locationFilter, setLocationFilter] = useState('');

  const { jobPostings, departments } = useGreenhouseJobContext();

  const jobsFilteredByLocation = locationFilter
    ? jobPostings.filter((jp) => jp.location.toLowerCase() === locationFilter.toLowerCase())
    : jobPostings;

  const buildLocationSelectionOptions = (jobs) => {
    const selectableLocations = JobPosting.getLocationsFromJobPostings(jobs);
    const result = selectableLocations.map((selectableLocation) => ({
      value: selectableLocation,
      label: selectableLocation,
    }));

    result.unshift({
      value: '',
      label: 'All locations',
    });

    return result;
  };

  const expandableJobCategoriesTile = Object.keys(departments).map((team) => {
    const jobsForTeam = jobsFilteredByLocation.filter((jp) => jp.team === team);
    const jobListTile = (
      <JobListTile
        jobListingsForTeam={jobsForTeam}
        key={team}
        team={team}
        trackClick={trackClick}
      />);

    return jobListTile;
  });

  return (
    <section
      css={styles.section}
      id={'open-positions-section'}
    >
      <H2
        css={styles.openPositionsTitle}
        id={'view-all-openings'}
      >
        Open positions
      </H2>
      <div css={styles.jobLocationSelect}>
        <Select
          autoComplete={'shipping address-level1'}
          inputId={'job-location-selection'}
          inputName={'job-location-selection'}
          onChange={setLocationFilter}
          options={buildLocationSelectionOptions(jobPostings)}
          placeholder={'Filter by location'}
          selectedValue={locationFilter}
        />
      </div>
      <div>
        {expandableJobCategoriesTile}
      </div>
    </section>
  );
};

GreenhouseOpenPositionsSection.propTypes = {
  trackClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  section: {
    padding: '80px 20px',
    backgroundColor: Colors.nearWhite(),

    ...Responsive.md({
      padding: '100px 30px',
    }),
  },

  openPositionsTitle: {
    textAlign: 'center',
  },

  jobLocationSelect: {
    margin: 25,

    ...Responsive.sm({
      maxWidth: 288,
      margin: '50px auto 25px',
    }),
  },
});

export default GreenhouseOpenPositionsSection;
