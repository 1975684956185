import ProjectContext from '@root/core/src/contexts/project/project-context';
import PropTypes from '@root/vendor/prop-types';
import UrlConversionTrackingService from '@root/gatsby-contentful-core/src/services/url-conversion-tracking-service';
import environmentalizeUrl from '@root/gatsby-contentful-core/src/utils/environmentalize-url';
import { Link as GatsbyLink } from 'gatsby';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isAnyRootProjectLink, isEmailAddressLink, isOnelink, isPdfLink, isPhoneNumberLink, isProjectLink } from '@root/gatsby-contentful-core/src/utils/detect-link-type';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { useContext } from '@root/vendor/react';
import { useLocation } from '@reach/router';

export default function Link({
  children,
  environmentalizeUrlFnToUse = environmentalizeUrl,
  isTabbable = true,
  onClick,
  to,
  ...props
}) {
  const { project } = useContext(ProjectContext);

  const location = useLocation();
  const tabIndex = isTabbable ? '0' : '-1';
  const href = environmentalizeUrlFnToUse(to);

  const isPhoneNumber = isPhoneNumberLink(to);
  const isEmailAddress = isEmailAddressLink(to);
  const validUrl = href.startsWith('http') && new URL(href);

  const handleClick = (event) => {
    if (isEmailAddress || isPhoneNumber) { return; }

    event.preventDefault();
    onClick && onClick(event);

    if (!to) { return; }

    UrlConversionTrackingService.trackConversionForUrl(to);

    if (!validUrl || validUrl.hostname === location.hostname && !!validUrl.hash) {
      const sectionId = href.split('#')[1];
      if (sectionId) {
        const section = document.getElementById(sectionId);

        if (section) {
          event.preventDefault();
          section.scrollIntoView({
            behavior: 'smooth',
          });
          return;
        }
      }
    }

    if (_isRootWebPageLink(to) || isOnelink(to)) {
      if (event.metaKey || event.ctrlKey) {
        window.open(href);
      } else {
        window.setTimeout(() => {
          // eslint-disable-next-line root/prevent-use-of-window-location
          window.location = href;
        }, 100);
      }
      return;
    }

    window.open(href);
  };

  const getExternalLinkValues = () => {
    if (_isRootWebPageLink(to) || isEmailAddress || isPhoneNumber || isOnelink(to)) {
      return {
        target: null,
        rel: null,
      };
    }

    return {
      target: '_blank',
      rel: 'noopener',
    };
  };

  const _isRootWebPageLink = (url) => {
    return !isPdfLink(url) && !isEmailAddress && isAnyRootProjectLink(url);
  };

  if (isPhoneNumber && !isMobileBrowser()) {
    return (
      <span>{children}</span>
    );
  }

  if (isProjectLink(project, to)) {
    return (
      <GatsbyLink
        activeClassName={'active'}
        css={styles.link}
        data-testid={'internal-gatsby-link'}
        onClick={handleClick}
        tabIndex={tabIndex}
        to={href}
        {...props}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      css={styles.link}
      data-testid={'external-link'}
      href={href}
      onClick={handleClick}
      tabIndex={tabIndex}
      {...getExternalLinkValues()}
      {...props}
    >
      {children}
    </a>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecoration: 'none',
  },
});

Link.propTypes = {
  children: PropTypes.node.isRequired,
  environmentalizeUrlFnToUse: PropTypes.func,
  isTabbable: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};
