import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function DontSeeYourJobSection({
  headline,
  body,
  button,
}) {
  return (
    <section css={styles.section}>
      <div css={styles.wrap}>
        <div css={styles.textContainer}>
          <H2>{headline}</H2>
          <P1>{body}</P1>
        </div>

        <LinkButton
          to={button.to}
          type={button.type}
        >
          {button.text}
        </LinkButton>
      </div>
    </section>
  );
}

DontSeeYourJobSection.propTypes = {
  body: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(LinkButtonModel.types)).isRequired,
  }).isRequired,
  headline: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: '100px 20px',
  },
  wrap: {
    width: 'min(100%, 690px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  textContainer: {
    '& > * + *': { marginTop: 12 },
    marginBottom: 36,
  },
});
