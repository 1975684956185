import MinusIcon from '@root/core/src/components/icons/minus-icon';
import PlusIcon from '@root/core/src/components/icons/plus-icon';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RootCareerIcon from '@root/careers/src/components/icons/root-career-icon';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H3, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { pluralize } from '@root/core/src/utils/strings';

function JobListTileHeader({
  toggleIsOpen,
  isOpen,
  numberOfOpenPositions,
  team,
}) {
  const isEmpty = numberOfOpenPositions === 0;
  const classNames = ['tile-header'];

  let containerRef = null;

  if (isEmpty) {
    classNames.push('is-empty');
  }

  const simulateClickOnEnterKey = (e) => {
    // Allow for the enter key to act as a click
    if (e.keyCode === 13) {
      containerRef.click();
    }
  };

  return (
    <div
      aria-label={`${team} jobs dropdown`}
      className={classNames.join(' ')}
      css={styles.jobListTileHeader}
      onClick={toggleIsOpen}
      onKeyDown={simulateClickOnEnterKey}
      ref={(div) => containerRef = div}
      tabIndex={'0'}
    >
      <div css={styles.teamTitleAndIconContainer}>
        <RootCareerIcon
          containerStyles={styles.rootCareerIcon}
          strokeColor={isEmpty ? Colors.gray40() : Colors.rootOrange()}
          team={team}
        />
        <H3
          css={isEmpty ? styles.teamNameTitleNoListings : styles.teamNameTitle}
          data-testid={'team-title'}
        >{team}
        </H3>
      </div>

      {!isEmpty && (
        <>
          <P1 css={styles.headerOpenPositionCopy}>
            {numberOfOpenPositions} open {pluralize('position', numberOfOpenPositions)}
          </P1>
          <div css={styles.headerIcon}>
            {isOpen ? <MinusIcon size={25} /> : <PlusIcon size={25} />}
          </div>
        </>
      )}
    </div>
  );
}

JobListTileHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  numberOfOpenPositions: PropTypes.number.isRequired,
  team: PropTypes.string.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  jobListTileHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 80,
    cursor: 'pointer',
    userSelect: 'none',

    ...Responsive.sm({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 110,
    }),

    '&.is-empty:hover': {
      cursor: 'default',
    },
  },

  teamNameTitle: {
    fontSize: 25,

    ...Responsive.sm({
      transition: 'color 200ms cubic-bezier(0.37, 0, 0.28, 1)',

      '.tile-header:hover &': {
        color: Colors.rootOrange(),
      },
    }),
  },

  headerOpenPositionCopy: {
    ...Responsive.sm({
      marginRight: 40,
    }),
  },

  teamTitleAndIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,

    ...Responsive.sm({
      flex: 1,
    }),
  },

  rootCareerIcon: {
    display: 'none',

    ...Responsive.sm({
      display: 'block',
      width: 60,
      height: 60,
      marginRight: 40,
    }),
  },

  headerIcon: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    minWidth: 25,
  },
});

export default JobListTileHeader;
