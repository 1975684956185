import JobDescription from '@root/careers/src/components/job-description';
import JobListingHeader from '@root/careers/src/components/job-listing-header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SharedJobListingInfo from '@root/careers/src/components/shared-job-listing-info';
import { useGreenhouseJobContext } from '@root/careers/src/contexts/greenhouse-job-context';

const JobContent = ({
  jobListingContentfulData, jobId,
}) => {
  const { jobPostings } = useGreenhouseJobContext();

  const getRandomTeam = (teams) => {
    const random = Math.floor(Math.random() * teams.length);
    return teams[random];
  };

  const jobData = jobPostings.find((job) => job.id === jobId);

  const contentfulTeamData = jobListingContentfulData.teams.find((team) => team.team === jobData.slug) || getRandomTeam(jobListingContentfulData.teams);

  const JOB_URL = `https://boards.greenhouse.io/rootinsurance/jobs/${jobId}?gh_src=joinroot`;

  return (
    <>
      <JobListingHeader
        headerPhoto={contentfulTeamData.headerPhoto}
        jobTitle={jobData.jobTitle}
        location={jobData.location}
        mobileHeaderPhoto={contentfulTeamData.mobileHeaderPhoto}
      />
      <JobDescription description={jobData.description} />
      <SharedJobListingInfo
        applyButtonCopy={jobListingContentfulData.applyButtonCopy}
        applyButtonDescription={jobListingContentfulData.applyButtonDescription}
        applyUrl={JOB_URL}
        mobileSharedJobListingPhoto={jobListingContentfulData.mobileSharedJobListingPhoto}
        sharedJobListingInfoPhoto={jobListingContentfulData.sharedJobListingInfoPhoto}
        sharedJobListingText={jobListingContentfulData.sharedJobListingText}
      />
    </>
  );
};

JobContent.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobListingContentfulData: PropTypes.object.isRequired,
};

export default JobContent;
