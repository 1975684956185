import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootClaimsIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-claims-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9999 0H30V0.000578333H0.000790796V30.0001H0V60H30V30.0005H30.0007V30H59.9999V0Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M30 0V-1H29V0H30ZM59.9999 0H60.9999V-1H59.9999V0ZM30 0.000578333V1.00058H31V0.000578333H30ZM0.000790796 0.000578333V-0.999422H-0.999209V0.000578333H0.000790796ZM0.000790796 30.0001V31.0001H1.00079V30.0001H0.000790796ZM0 30.0001V29.0001H-1V30.0001H0ZM0 60H-1V61H0V60ZM30 60V61H31V60H30ZM30 30.0005V29.0005H29V30.0005H30ZM30.0007 30.0005V31.0005H31.0007V30.0005H30.0007ZM30.0007 30V29H29.0007V30H30.0007ZM59.9999 30V31H60.9999V30H59.9999ZM30 1H59.9999V-1H30V1ZM31 0.000578333V0H29V0.000578333H31ZM30 -0.999422H0.000790796V1.00058H30V-0.999422ZM-0.999209 0.000578333V30.0001H1.00079V0.000578333H-0.999209ZM0.000790796 29.0001H0V31.0001H0.000790796V29.0001ZM-1 30.0001V60H1V30.0001H-1ZM0 61H30V59H0V61ZM31 60V30.0005H29V60H31ZM30 31.0005H30.0007V29.0005H30V31.0005ZM31.0007 30.0005V30H29.0007V30.0005H31.0007ZM59.9999 29H30.0007V31H59.9999V29ZM58.9999 0V30H60.9999V0H58.9999Z'}
        fill={strokeColor}
        mask={'url(#root-claims-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'29.2344'}
        x2={'29.2344'}
        y1={'9.87311'}
        y2={'29.9997'}
      />
      <line
        stroke={strokeColor}
        x1={'4.37114e-08'}
        x2={'20.7595'}
        y1={'29.5001'}
        y2={'29.5001'}
      />
    </svg>
  );
}

RootClaimsIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

