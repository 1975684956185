import JobPosting from '@root/careers/src/models/job-posting';
import PropTypes from '@root/vendor/prop-types';
import RootActuarialIcon from '@root/careers/src/components/icons/root-career-icons/actuarial';
import RootClaimsIcon from '@root/careers/src/components/icons/root-career-icons/claims';
import RootCorporateDevelopmentAndStrategyIcon from '@root/careers/src/components/icons/root-career-icons/corporate-development';
import RootCreativeIcon from '@root/careers/src/components/icons/root-career-icons/creative';
import RootCustomerServiceIcon from '@root/careers/src/components/icons/root-career-icons/customer-service';
import RootDataScienceIcon from '@root/careers/src/components/icons/root-career-icons/data-science';
import RootEngineeringIcon from '@root/careers/src/components/icons/root-career-icons/engineering';
import RootFinanceIcon from '@root/careers/src/components/icons/root-career-icons/finance';
import RootLegalIcon from '@root/careers/src/components/icons/root-career-icons/legal';
import RootMarketingIcon from '@root/careers/src/components/icons/root-career-icons/marketing';
import RootPeopleIcon from '@root/careers/src/components/icons/root-career-icons/people';
import RootProductIcon from '@root/careers/src/components/icons/root-career-icons/product';
import { Colors } from '@root/core/src/utils/styles';

const TeamToIconMapping = {
  [JobPosting.TEAMS.ACTUARIAL]: RootActuarialIcon,
  [JobPosting.TEAMS.ANALYTICS]: RootProductIcon,
  [JobPosting.TEAMS.BUSINESS_DEVELOPMENT_AND_STRATEGY]: RootCorporateDevelopmentAndStrategyIcon,
  [JobPosting.TEAMS.CLAIMS]: RootClaimsIcon,
  [JobPosting.TEAMS.CREATIVE]: RootCreativeIcon,
  [JobPosting.TEAMS.CUSTOMER_SERVICE]: RootCustomerServiceIcon,
  [JobPosting.TEAMS.DATA_SCIENCE]: RootDataScienceIcon,
  [JobPosting.TEAMS.ENGINEERING]: RootEngineeringIcon,
  [JobPosting.TEAMS.FINANCE]: RootFinanceIcon,
  [JobPosting.TEAMS.LEGAL]: RootLegalIcon,
  [JobPosting.TEAMS.MARKETING]: RootMarketingIcon,
  [JobPosting.TEAMS.PEOPLE]: RootPeopleIcon,
  [JobPosting.TEAMS.PRODUCT]: RootProductIcon,
  [JobPosting.TEAMS.RESEARCH_AND_DEVELOPMENT]: RootCustomerServiceIcon,
};
export default function RootCareerIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
  containerStyles,
  team,
}) {
  const IconComponent = TeamToIconMapping[team] || RootActuarialIcon;

  return (
    <div
      css={containerStyles}
      data-testid={`${team}-icon`}
    >
      <IconComponent
        size={size}
        strokeColor={strokeColor}
      />
    </div>
  );
}

RootCareerIcon.propTypes = {
  containerStyles: PropTypes.any,
  size: PropTypes.number,
  strokeColor: PropTypes.string,
  style: PropTypes.any,
  team: PropTypes.string,
};
