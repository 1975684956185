import BlockComponentMap from '@root/joinroot.com/src/models/block-component-map';
import CoreBasicPage from '@root/gatsby-contentful-core/src/components/pages/core-basic-page';
import Footer from '@root/joinroot.com/src/components/footer/footer';
import HubspotFormsProvider from '@root/joinroot.com/src/contexts/hubspot-forms/hubspot-forms-provider';
import NavWrapper from '@root/joinroot.com/src/components/nav/nav-wrapper';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import { graphql } from 'gatsby';

export default function BasicPage({ data, location }) {
  return (
    <HubspotFormsProvider forms={data.allHubspotForm.nodes}>
      <CoreBasicPage
        baseUrl={environment.websiteBaseUrl}
        blockComponentMap={new BlockComponentMap()}
        data={data}
        footer={Footer}
        location={location}
        nav={NavWrapper}
      />
    </HubspotFormsProvider>
  );
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
query ($id: String!) {
  contentfulBasicPage(id: {eq: $id}) {
    id

    publishingMetadata {
      ...ContentfulPublishingMetadata_fragment
    }

    ctaText
    ctaLink
    disableStickyFooter
    disableCtaButton

    blocks {
      ...ContentfulAvailabilityMap_fragment
      ...ContentfulCustomReactComponent_fragment
      ...ContentfulDisclaimerSection_fragment
      ...ContentfulExpandableSection_fragment
      ...ContentfulFaqSection_fragment
      ...ContentfulHero_fragment
      ...ContentfulHeroSimple_fragment
      ...ContentfulIconToutGrid_fragment
      ...ContentfulIconToutSection_fragment
      ...ContentfulIconToutSideBySide_fragment
      ...ContentfulImageToutSection_fragment
      ...ContentfulImageToutSideBySideSection_fragment
      ...ContentfulPressSection_fragment
      ...ContentfulQuoteToutSection_fragment
      ...ContentfulRichTextSection_fragment
      ...ContentfulSectionSpacer_fragment
      ...ContentfulTableSection_fragment
      ...ContentfulVariationContainer_fragment
      ...ContentfulVideoToutSection_fragment
    }
  }

  allHubspotForm {
    nodes {
      portalId
      id
      name
      isPublished
      submitText
      inlineMessage
      formFieldGroups {
        richText {
          content
          type
        }
        fields {
          label
          name
          placeholder
          required
          options {
            description
            label
            value
          }
          description
          fieldType
        }
      }
    }
  }
}
`;
