import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootEngineeringIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-engineering-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.8633 0.405273H29.8633V0.405406H0V30.3194V30.4054V60.3194H30V30.4054V30.4053H59.8633V0.405273Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.8633 0.405273V-0.594727H28.8633V0.405273H29.8633ZM59.8633 0.405273H60.8633V-0.594727H59.8633V0.405273ZM29.8633 0.405406V1.40541H30.8633V0.405406H29.8633ZM0 0.405406V-0.594594H-1V0.405406H0ZM0 60.3194H-1V61.3194H0V60.3194ZM30 60.3194V61.3194H31V60.3194H30ZM30 30.4053V29.4053H29V30.4053H30ZM59.8633 30.4053V31.4053H60.8633V30.4053H59.8633ZM29.8633 1.40527H59.8633V-0.594727H29.8633V1.40527ZM30.8633 0.405406V0.405273H28.8633V0.405406H30.8633ZM29.8633 -0.594594H0V1.40541H29.8633V-0.594594ZM-1 0.405406V30.3194H1V0.405406H-1ZM-1 30.3194V30.4054H1V30.3194H-1ZM-1 30.4054V60.3194H1V30.4054H-1ZM0 61.3194H30V59.3194H0V61.3194ZM31 60.3194V30.4054H29V60.3194H31ZM31 30.4054V30.4053H29V30.4054H31ZM59.8633 29.4053H30V31.4053H59.8633V29.4053ZM58.8633 0.405273V30.4053H60.8633V0.405273H58.8633Z'}
        fill={strokeColor}
        mask={'url(#root-engineering-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'1.13672'}
        x2={'59.9975'}
        y1={'15.855'}
        y2={'15.8551'}
      />
      <line
        stroke={strokeColor}
        x1={'1.13672'}
        x2={'59.9975'}
        y1={'1.17145'}
        y2={'1.17146'}
      />
      <line
        stroke={strokeColor}
        x1={'1.13672'}
        x2={'59.9975'}
        y1={'29.9052'}
        y2={'29.9052'}
      />
      <line
        stroke={strokeColor}
        x1={'1.13672'}
        x2={'29.9975'}
        y1={'45.2222'}
        y2={'45.2222'}
      />
      <line
        stroke={strokeColor}
        x1={'4.37114e-08'}
        x2={'30'}
        y1={'59.9053'}
        y2={'59.9053'}
      />
      <line
        stroke={strokeColor}
        x1={'0.764954'}
        x2={'0.764956'}
        y1={'60.4053'}
        y2={'0.405254'}
      />
      <line
        stroke={strokeColor}
        x1={'15.0703'}
        x2={'15.0703'}
        y1={'60.4053'}
        y2={'0.405254'}
      />
      <line
        stroke={strokeColor}
        x1={'44.4367'}
        x2={'44.4367'}
        y1={'30.4052'}
        y2={'0.405203'}
      />
      <line
        stroke={strokeColor}
        x1={'29.4992'}
        x2={'29.4992'}
        y1={'60.4053'}
        y2={'0.405254'}
      />
      <line
        stroke={strokeColor}
        x1={'59.5005'}
        x2={'59.5005'}
        y1={'30.4052'}
        y2={'0.405203'}
      />
    </svg>
  );
}

RootEngineeringIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
