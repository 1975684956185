import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootFinanceIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-finance-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9996 0.000289917H29.9996V0.00046132H0V30.0003V30.0005V60.0003H30V30.0005V30.0003V30.0003H59.9996V0.000289917Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9996 0.000289917V-0.99971H28.9996V0.000289917H29.9996ZM59.9996 0.000289917H60.9996V-0.99971H59.9996V0.000289917ZM29.9996 0.00046132V1.00046H30.9996V0.00046132H29.9996ZM0 0.00046132V-0.999539H-1V0.00046132H0ZM0 60.0003H-1V61.0003H0V60.0003ZM30 60.0003V61.0003H31V60.0003H30ZM30 30.0003V29.0003H29V30.0003H30ZM59.9996 30.0003V31.0003H60.9996V30.0003H59.9996ZM29.9996 1.00029H59.9996V-0.99971H29.9996V1.00029ZM30.9996 0.00046132V0.000289917H28.9996V0.00046132H30.9996ZM29.9996 -0.999539H0V1.00046H29.9996V-0.999539ZM-1 0.00046132V30.0003H1V0.00046132H-1ZM-1 30.0003V30.0005H1V30.0003H-1ZM-1 30.0005V60.0003H1V30.0005H-1ZM0 61.0003H30V59.0003H0V61.0003ZM31 60.0003V30.0005H29V60.0003H31ZM31 30.0005V30.0003H29V30.0005H31ZM31 30.0003V30.0003H29V30.0003H31ZM59.9996 29.0003H30V31.0003H59.9996V29.0003ZM58.9996 0.000289917V30.0003H60.9996V0.000289917H58.9996Z'}
        fill={strokeColor}
        mask={'url(#root-finance-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'29.4996'}
        x2={'29.4996'}
        y1={'30'}
        y2={'4.57982e-05'}
      />
      <line
        stroke={strokeColor}
        x1={'35.5742'}
        x2={'35.5742'}
        y1={'30'}
        y2={'2.18557e-08'}
      />
      <line
        stroke={strokeColor}
        x1={'41.6517'}
        x2={'41.6517'}
        y1={'30'}
        y2={'4.57982e-05'}
      />
      <line
        stroke={strokeColor}
        x1={'47.7266'}
        x2={'47.7266'}
        y1={'30'}
        y2={'2.18557e-08'}
      />
      <line
        stroke={strokeColor}
        x1={'53.804'}
        x2={'53.804'}
        y1={'30'}
        y2={'4.57982e-05'}
      />
    </svg>
  );
}

RootFinanceIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

