import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

export default function MinusIcon({ strokeColor = Colors.rootOrange(), size = 17 }) {
  return (
    <svg
      fill={'none'}
      height={'2'}
      viewBox={'0 0 24 2'}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <line
        stroke={strokeColor}
        strokeWidth={'2'}
        x2={size}
        y1={'1'}
        y2={'1'}
      />
    </svg>
  );
}

MinusIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
