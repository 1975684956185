import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import Market from '@root/core/src/models/market';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import ProductTile from '@root/joinroot.com/src/components/all-products-availability/product-tile';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RootUnavailableFormFlow from '@root/joinroot.com/src/components/forms/root-unavailable-form-flow';
import Select from '@root/core/src/components/select/select';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useGetMarketSupportByMarket from '@root/joinroot.com/src/api/use-get-market-support-by-market';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { H1Display, Subhead } from '@root/gatsby-contentful-core/src/components/text';
import { graphql, useStaticQuery } from 'gatsby';

const marketsSortedByLabel = Market.OPTIONS.sort((a, b) => a.label > b.label ? 1 : -1);

export default function AllProductsAvailabilitySection({ anchor, sectionSpacing = {} }) {
  const { trackClick, trackEvent } = useAnalytics('ALL_PRODUCTS_AVAILABILITY_HEADER', true);
  const [selectedMarket, setSelectedMarket] = useState('');
  const [isEmailModalVisible, setIsEmailModalVisible] = useState('');
  const marketSupport = useGetMarketSupportByMarket(selectedMarket);

  const data = useStaticQuery(graphql`
    query {
      auto: file(relativePath: { eq: "all-products-availability/auto.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      renters: file(relativePath: { eq: "all-products-availability/renters.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      homeowners: file(relativePath: { eq: "all-products-availability/homeowners.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `);

  const tileContent = [
    {
      productIsAvailable: marketSupport?.auto,
      img: data.auto.childImageSharp.gatsbyImageData,
      alt: 'car',
      title: 'Car',
      marketNotSelected: {
        body: 'Better rates for better drivers. You could save hundreds.',
        button: null,
      },
      availableInMarket: {
        body: 'Better rates for better drivers. You could save hundreds.',
        button: {
          onClick: () => trackClick('AUTO_GET_A_QUOTE_BUTTON'),
          to: environment.quoteAppBaseUrl,
          type: LinkButtonModel.types.PRIMARY,
          text: 'Get a quote',
        },
      },
      notAvailableInMarket: {
        body: `We’re not in ${Market.MAPPING[selectedMarket]} yet, but we’ll email you when we are.`,
        button: {
          onClick: () => {
            trackEvent('AUTO_UNAVAILABLE_VIEWED');
            setIsEmailModalVisible('AUTO');
          },
          to: '#',
          type: LinkButtonModel.types.SECONDARY,
          text: 'Email me',
        },
      },
    },
    {
      productIsAvailable: marketSupport?.renters,
      img: data.renters.childImageSharp.gatsbyImageData,
      alt: 'apartment',
      title: 'Renters',
      marketNotSelected: {
        body: 'Your stuff, protected at home or away. Starting at $6/month.',
        button: null,
      },
      availableInMarket: {
        body: 'Your stuff, protected at home or away. Starting at $6/month.',
        button: {
          onClick: () => trackClick('RENTERS_GET_A_QUOTE_BUTTON'),
          to: environment.rentersAppBaseUrl,
          type: LinkButtonModel.types.PRIMARY,
          text: 'Get a quote',
        },
      },
      notAvailableInMarket: {
        body: `We’re not in ${Market.MAPPING[selectedMarket]} yet, but we’ll email you when we are.`,
        button: {
          onClick: () => {
            trackEvent('RENTERS_UNAVAILABLE_VIEWED');
            setIsEmailModalVisible('RENTERS');
          },
          to: '#',
          type: LinkButtonModel.types.SECONDARY,
          text: 'Email me',
        },
      },
    },
    {
      productIsAvailable: marketSupport?.homeowners,
      img: data.homeowners.childImageSharp.gatsbyImageData,
      alt: 'home',
      title: 'Homeowners',
      marketNotSelected: {
        body: 'Save on home when you bundle with car insurance. Download the app to get started.',
        button: null,
      },
      availableInMarket: {
        body: (
          <>
            Save on home when you bundle with car insurance. Download the app to get started.
            {marketSupport?.homeownersTieInRequired &&
              <Link
                css={{
                  ...Theme.link(),
                  display: 'block',
                }}
                to={'/homeowners-insurance/home-only/'}
              >
                Want homeowners only?
              </Link>
            }
          </>
        ),
        button: {
          onClick: () => trackClick('HOMEOWNERS_GET_A_QUOTE_BUTTON'),
          to: 'https://root.onelink.me/2133524785?pid=all_products_availability&c=homeowners-get-a-quote-button',
          type: LinkButtonModel.types.PRIMARY,
          text: 'Get a quote',
        },
      },
      notAvailableInMarket: {
        body: `Root Home isn’t available in ${Market.MAPPING[selectedMarket]} yet, but we’ll email you when it is.`,
        button: {
          onClick: () => {
            trackEvent('HOMEOWNERS_UNAVAILABLE_VIEWED');
            setIsEmailModalVisible('HOMEOWNERS');
          },
          to: '#',
          type: LinkButtonModel.types.SECONDARY,
          text: 'Email me',
        },
      },
    },
  ];

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={styles.section}
      id={anchor}
    >
      <div css={[styles.wrap, sectionSpacingStyles]}>
        <H1Display css={styles.headline}>See where Root is&nbsp;available</H1Display>
        <Subhead css={styles.subhead}>Protect you and your things with coverage that’s fair&nbsp;and&nbsp;affordable.</Subhead>
        <div css={styles.selectBoxWrap}>
          <Select
            inputId={'state-select'}
            inputName={'state-select'}
            onChange={(value) => {
              trackClick('US_STATE_SELECTOR');
              setSelectedMarket(value);
            }}
            options={marketsSortedByLabel}
            placeholder={'Select your state'}
            selectedValue={selectedMarket || ''}
          />
        </div>
        <div css={styles.tilesContainer}>
          {tileContent.map((tile, i) => (
            <ProductTile
              key={i}
              {...tile}
            />
          ))}
        </div>
      </div>
      <Modal
        isShowing={!!isEmailModalVisible}
        name={'all-products-availability-modal'}
        onClose={() => setIsEmailModalVisible('')}
      >
        {selectedMarket &&
          <RootUnavailableFormFlow
            analytics={{
              trackClick,
              trackEvent,
            }}
            market={Market.MAPPING[selectedMarket]}
            onClose={() => setIsEmailModalVisible('')}
            product={isEmailModalVisible || 'AUTO'}
          />
        }
      </Modal>
    </section>
  );
}

AllProductsAvailabilitySection.propTypes = {
  anchor: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.white(),
  },

  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px 20px',
    width: '100%',
    maxWidth: 1200,

    ...Responsive.lg({
      padding: '100px 20px',
    }),
  },

  headline: {
    textAlign: 'center',
  },

  subhead: {
    margin: '16px 0 50px',
    textAlign: 'center',
  },

  selectBoxWrap: {
    width: '100%',
    maxWidth: 670,
    marginBottom: 40,
  },

  tilesContainer: {
    display: 'flex',
    flexDirection: 'column',

    ...Responsive.md({
      flexDirection: 'row',
    }),
  },
});
