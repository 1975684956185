import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootActuarialIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-actuarial-path-icon-1'}
      >
        <path
          clipRule={'evenodd'}
          d={'M60 0H0V60L30 30L60 0Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M0 0V-1H-1V0H0ZM60 0L60.7071 0.707107L62.4142 -1H60V0ZM0 60H-1V62.4142L0.707107 60.7071L0 60ZM0 1H60V-1H0V1ZM1 60V0H-1V60H1ZM0.707107 60.7071L30.7071 30.7071L29.2929 29.2929L-0.707107 59.2929L0.707107 60.7071ZM30.7071 30.7071L60.7071 0.707107L59.2929 -0.707107L29.2929 29.2929L30.7071 30.7071Z'}
        fill={strokeColor}
        mask={'url(#root-actuarial-path-icon-1)'}
      />
      <mask
        fill={'white'}
        id={'root-actuarial-path-icon-2'}
      >
        <path
          clipRule={'evenodd'}
          d={'M0.000549316 60H30V30.0005L0.000549316 60ZM30.0006 30L60 0.000534058V30H30.0006Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        clipRule={'evenodd'}
        d={'M0.000549316 60H30V30.0005L0.000549316 60ZM30.0006 30L60 0.000534058V30H30.0006Z'}
        fill={strokeColor}
        fillRule={'evenodd'}
      />
      <path
        d={'M30 60V61H31V60H30ZM0.000549316 60L-0.706557 59.2929L-2.41366 61H0.000549316V60ZM30 30.0005H31V27.5863L29.2929 29.2934L30 30.0005ZM30.0006 30L29.2934 29.2929L27.5863 31H30.0006V30ZM60 0.000534058H61V-2.41368L59.2929 -0.706573L60 0.000534058ZM60 30V31H61V30H60ZM30 59H0.000549316V61H30V59ZM29 30.0005V60H31V30.0005H29ZM0.707656 60.7071L30.7071 30.7076L29.2929 29.2934L-0.706557 59.2929L0.707656 60.7071ZM30.7077 30.7071L60.7071 0.707641L59.2929 -0.706573L29.2934 29.2929L30.7077 30.7071ZM59 0.000534058V30H61V0.000534058H59ZM60 29H30.0006V31H60V29Z'}
        fill={strokeColor}
        mask={'url(#root-actuarial-path-icon-2)'}
      />
    </svg>
  );
}

RootActuarialIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

