import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export default function RootCorporateDevelopmentAndStrategyIcon({
  strokeColor = Colors.rootOrange(),
  size = 60,
}) {
  return (
    <svg
      fill={'none'}
      height={size + 1}
      viewBox={`0 0 ${size} ${size + 1}`}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask
        fill={'white'}
        id={'root-corporate-development-icon-path'}
      >
        <path
          clipRule={'evenodd'}
          d={'M59.9991 0.813477H29.9991V0.813846H0.000342895V30.8134H0V60.8134H30V30.8138H30.0003V30.8135H59.9991V0.813477Z'}
          fillRule={'evenodd'}
        />
      </mask>
      <path
        d={'M29.9991 0.813477V-0.186523H28.9991V0.813477H29.9991ZM59.9991 0.813477H60.9991V-0.186523H59.9991V0.813477ZM29.9991 0.813846V1.81385H30.9991V0.813846H29.9991ZM0.000342895 0.813846V-0.186154H-0.999657V0.813846H0.000342895ZM0.000342895 30.8134V31.8134H1.00034V30.8134H0.000342895ZM0 30.8134V29.8134H-1V30.8134H0ZM0 60.8134H-1V61.8134H0V60.8134ZM30 60.8134V61.8134H31V60.8134H30ZM30 30.8138V29.8138H29V30.8138H30ZM30.0003 30.8138V31.8138H31.0003V30.8138H30.0003ZM30.0003 30.8135V29.8135H29.0003V30.8135H30.0003ZM59.9991 30.8135V31.8135H60.9991V30.8135H59.9991ZM29.9991 1.81348H59.9991V-0.186523H29.9991V1.81348ZM30.9991 0.813846V0.813477H28.9991V0.813846H30.9991ZM29.9991 -0.186154H0.000342895V1.81385H29.9991V-0.186154ZM-0.999657 0.813846V30.8134H1.00034V0.813846H-0.999657ZM0.000342895 29.8134H0V31.8134H0.000342895V29.8134ZM-1 30.8134V60.8134H1V30.8134H-1ZM0 61.8134H30V59.8134H0V61.8134ZM31 60.8134V30.8138H29V60.8134H31ZM30 31.8138H30.0003V29.8138H30V31.8138ZM31.0003 30.8138V30.8135H29.0003V30.8138H31.0003ZM59.9991 29.8135H30.0003V31.8135H59.9991V29.8135ZM58.9991 0.813477V30.8135H60.9991V0.813477H58.9991Z'}
        fill={strokeColor}
        mask={'url(#root-corporate-development-icon-path)'}
      />
      <line
        stroke={strokeColor}
        x1={'4.37114e-08'}
        x2={'49.8734'}
        y1={'11.073'}
        y2={'11.073'}
      />
      <line
        stroke={strokeColor}
        x1={'10.126'}
        x2={'59.9994'}
        y1={'21.0726'}
        y2={'21.0726'}
      />
    </svg>
  );
}

RootCorporateDevelopmentAndStrategyIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};

