import BlockComponentMap from '@root/joinroot.com/src/models/block-component-map';
import BlockRenderer from '@root/gatsby-contentful-core/src/components/blocks/block-renderer';
import CorePageTemplate from '@root/gatsby-contentful-core/src/components/pages/core-page-template';
import Footer from '@root/joinroot.com/src/components/footer/footer';
import NavWrapper from '@root/joinroot.com/src/components/nav/nav-wrapper';
import PropTypes from '@root/vendor/prop-types';
import buildPathFromSlug from '@root/gatsby-contentful-core/src/utils/build-path-from-slug';
import calculateSectionSpacing from '@root/gatsby-contentful-core/src/utils/calculate-section-spacing';
import environment from '@root/core/src/utils/environment';
import { graphql } from 'gatsby';

export default function BlogPostPage({ data, location }) {
  const blogPost = data.contentfulBlogPostPage;
  const analyticsContext = `BLOG_POST_${blogPost.id}`;
  const blocksWithSectionSpacingData = calculateSectionSpacing(blogPost.blocks, 'BLOG_POST');

  return (
    <CorePageTemplate
      analyticsContext={analyticsContext}
      ctaLink={blogPost.navigationOptions?.ctaLink}
      ctaText={blogPost.navigationOptions?.ctaText}
      deIndexPage={blogPost.publishingMetadata.deIndexPage}
      footer={Footer}
      includeHotjar={blogPost.publishingMetadata.includeHotjar}
      includeOptimizely={blogPost.publishingMetadata.includeOptimizely}
      isCtaButtonDisabled={blogPost.navigationOptions?.disableCtaButton}
      isStickyFooterDisabled={blogPost.navigationOptions?.disableStickyFooter}
      location={location}
      nav={NavWrapper}
      pageCanonical={`${environment.websiteBaseUrl}/blog${buildPathFromSlug(blogPost.publishingMetadata.slug)}`}
      pageDescription={blogPost.publishingMetadata.pageDescription}
      pageThumbnail={blogPost.publishingMetadata.pageThumbnail?.file?.url}
      pageTitle={blogPost.publishingMetadata.pageTitle}
    >
      {blocksWithSectionSpacingData && blocksWithSectionSpacingData.map((block) => (
        <BlockRenderer
          block={block}
          blockComponentMap={new BlockComponentMap()}
          key={block.id}
        />
      ))}
    </CorePageTemplate>
  );
}

BlogPostPage.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPostPage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      publishingMetadata: PropTypes.shape({
        deIndexPage: PropTypes.bool,
        includeHotjar: PropTypes.bool,
        includeOptimizely: PropTypes.bool,
        pageTitle: PropTypes.string.isRequired,
        pageDescription: PropTypes.string.isRequired,
        pageThumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        slug: PropTypes.string.isRequired,
      }).isRequired,
      navigationOptions: PropTypes.shape({
        ctaLink: PropTypes.string,
        ctaText: PropTypes.string,
        disableCtaButton: PropTypes.bool,
        disableStickyFooter: PropTypes.bool,
      }),
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          content: PropTypes.shape({
            json: PropTypes.object.isRequired,
          }),
          sys: PropTypes.shape({
            contentType: PropTypes.shape({
              sys: PropTypes.shape({
                id: PropTypes.string.isRequired,
              }),
            }),
          }),
        }).isRequired,
      ),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ($id: String!) {
    contentfulBlogPostPage(id: {eq: $id}) {
      id
      publishingMetadata {
        ...ContentfulPublishingMetadata_fragment
      }
      navigationOptions {
        ctaText
        ctaLink
        disableStickyFooter
        disableCtaButton
      }
      blocks {
        ...ContentfulAvailabilityMap_fragment
        ...ContentfulBlogHeaderSection_fragment
        ...ContentfulCustomReactComponent_fragment
        ...ContentfulDisclaimerSection_fragment
        ...ContentfulExpandableSection_fragment
        ...ContentfulFaqSection_fragment
        ...ContentfulHero_fragment
        ...ContentfulHeroSimple_fragment
        ...ContentfulIconToutGrid_fragment
        ...ContentfulIconToutSection_fragment
        ...ContentfulIconToutSideBySide_fragment
        ...ContentfulImageToutSection_fragment
        ...ContentfulImageToutSideBySideSection_fragment
        ...ContentfulPressSection_fragment
        ...ContentfulQuoteToutSection_fragment
        ...ContentfulBlogRelatedPostsSection_fragment
        ...ContentfulSectionSpacer_fragment
        ...ContentfulRichTextSection_fragment
        ...ContentfulVariationContainer_fragment
        ...ContentfulVideoToutSection_fragment
      }
    }
  }
  `;

