import DontSeeYourJobSection from '@root/joinroot.com/src/components/careers/dont-see-your-job-section';
import JobContentSection from '@root/careers/src/components/job-content-section';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PageTemplate from '@root/joinroot.com/src/components/pages/page-template';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { graphql } from 'gatsby';

const GreenhouseJobListing = ({
  data,
  location,
  pageContext: {
    jobId,
    jobTitle,
  },
}) => {
  const { trackClick } = useAnalytics('JOB_LISTING_PAGE', false);
  const jobListingContentfulData = data.contentfulJobListingPage;

  const pageTitle = jobTitle ?
    `${jobTitle} Job Opening | Careers at Root Insurance Company` :
    jobListingContentfulData.publishingMetadata.pageTitle;

  const DONT_SEE_YOUR_JOB_BODY = 'Send your resume and what you’d love to do to jobs@inc.joinroot.com.';
  const DONT_SEE_YOUR_JOB_HEADLINE = 'Don’t see your job?';

  return (
    <PageTemplate
      analyticsContext={'JOB_LISTING_PAGE'}
      isStickyFooterDisabled={true}
      location={location}
      mainStyles={styles}
      pageCanonical={`https://www.joinroot.com${location.pathname}`}
      pageDescription={jobListingContentfulData.publishingMetadata.pageDescription}
      pageThumbnail={jobListingContentfulData.publishingMetadata.pageThumbnail?.file?.url}
      pageTitle={pageTitle}
    >
      <JobContentSection
        data={data}
        jobId={jobId}
        jobListingContentfulData={jobListingContentfulData}
        trackClick={trackClick}
      />
      <DontSeeYourJobSection
        body={DONT_SEE_YOUR_JOB_BODY}
        button={{
          text: 'Email us',
          to: 'mailto:jobs@inc.joinroot.com',
          type: LinkButtonModel.types.PRIMARY,
        }}
        headline={DONT_SEE_YOUR_JOB_HEADLINE}
      />
    </PageTemplate>
  );
};

const styles = {
  paddingTop: 50,
  width: '100vw',
  ...Responsive.md({
    paddingTop: 104,
  }),
  ...Responsive.lg({
    paddingTop: 59,
  }),
};

GreenhouseJobListing.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    jobId: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
  }).isRequired,
};

export const query = graphql`
query {
  contentfulJobListingPage {
    applyButtonCopy
    contentful_id
    id
    applyButtonDescription {
      json
    }
    publishingMetadata {
      slug
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
    }

    sharedJobListingInfoPhoto {
      gatsbyImageData(layout: FULL_WIDTH, quality: 80)
    }

    sharedJobListingText {
      json
    }
    teams {
      team
      headerPhoto {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
      mobileHeaderPhoto {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
    }
  }

  allDepartments {
    nodes {
      jobs {
        title
        location {
          name
        }
        internal_job_id
      }
      name
    }
  }

  allJobs {
    nodes {
      id
      content
      demographic_question_set_id
      job_id
      id
      questions {
        label
        required
        values {
          label
          value
        }
      }
      title
      location {
        name
      }
    }
  }

  allDemographicQuestion {
    nodes {
      answer_type
      demographic_question_set_id
      name
      required
    }
  }
}
`;

export default GreenhouseJobListing;
