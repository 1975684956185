/* Altered code from https://github.com/rigoneri/indefinite-article.js */
export const determineIndefiniteArticle = (phrase) => {
  // Getting the first word
  const match = /\w+/.exec(phrase);
  if (!match) { return 'an'; }

  const word = match[0];

  const lowerCaseWord = word.toLowerCase();

  // Specific start of words that should be preceeded by 'an'
  const altCases = ['honest', 'honorable', 'hour'];
  for (const index in altCases) {
    if (lowerCaseWord.indexOf(altCases[index]) === 0) { return 'an'; }
  }

  // Single letter word which should be preceeded by 'an'
  if (lowerCaseWord.length === 1) {
    if ('aehilmnorsx'.indexOf(lowerCaseWord) >= 0) { return 'an'; } else { return 'a'; }
  }

  // Special cases where a word that begins with a vowel should be preceeded by 'a'
  const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/];
  for (const index in regexes) {
    if (lowerCaseWord.match(regexes[index])) { return 'a'; }
  }

  // Special capital words (UK, UN)
  if (word.match(/^U[NK][AIEO]/)) {
    return 'a';
  } else if (word === word.toUpperCase()) {
    if ('aedhilmnorsx'.indexOf(lowerCaseWord[0]) >= 0) { return 'an'; } else { return 'a'; }
  }

  // Basic method of words that begin with a vowel being preceeded by 'an'
  if ('aeiou'.indexOf(lowerCaseWord[0]) >= 0) { return 'an'; }

  return 'a';
};
