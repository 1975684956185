import ArtDirectedGatsbyImage from '@root/gatsby-contentful-core/src/components/art-directed-gatsby-image';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H1Display, H2, H4 } from '@root/gatsby-contentful-core/src/components/text';
import { determineIndefiniteArticle } from '@root/core/src/utils/indefinite-article';
import { getImage } from 'gatsby-plugin-image';
export default function JobListingHeader({
  headerPhoto,
  jobTitle,
  location,
  mobileHeaderPhoto,
}) {
  return (
    <header css={styles.headerContainer}>
      <ArtDirectedGatsbyImage
        alt={''}
        desktopImg={getImage(headerPhoto.gatsbyImageData)}
        loading={'eager'}
        mobileImg={getImage(mobileHeaderPhoto?.gatsbyImageData)}
        style={imageWrapperStyles}
      />
      <div css={styles.headerTextContainer}>
        <H2 css={styles.textWhite}>{`We’re hiring ${determineIndefiniteArticle(jobTitle)}`}</H2>
        <H1Display css={styles.textWhite}>{jobTitle}</H1Display>
        <H4 css={styles.textLocation}>{location}</H4>
      </div>
    </header>
  );
}

JobListingHeader.propTypes = {
  headerPhoto: PropTypes.object.isRequired,
  jobTitle: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  mobileHeaderPhoto: PropTypes.object,
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
    width: '100vw',
    overflow: 'hidden',
    ...Responsive.md({
      height: '480px',
      alignItems: 'flex-end',
    }),
  },
  headerTextContainer: {
    padding: '50px 20px',
    position: 'absolute',
    width: 'min(100%, 840px)',
    zIndex: 2,
    textAlign: 'center',
    ...Responsive.md({ textAlign: 'left' }),
  },
  textWhite: {
    color: Colors.white(),
    textShadow: `0 0 10px ${Colors.toRgba(Colors.black(), 0.3)}`,
  },
  textLocation: {
    marginTop: 20,
    color: Colors.rootOrange(),
    textShadow: `0 0 10px ${Colors.toRgba(Colors.black(), 0.3)}`,
  },
});

const imageWrapperStyles = {
  position: 'absolute !important',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};
